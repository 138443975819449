export const logo = ['608 134', `
  <title>TruAir Media</title>
<g>
<path style="fill:#434345;" d="M167.13,115.78c11,1.77,17.77,2.65,28.77,4.42c0.01,28.33,0.02,56.67,0.02,85
c0.01,6.22,0.01,12.44,8.8,11.54c7.69-0.78,8.97-6.73,8.97-12.68c-0.01-32.44-0.01-48.78-0.02-81.21
c11.41,1.84,14.63,2.76,26.04,4.6v74.67c0,18.21-14.28,32.35-36.26,35.88c-23.08,3.71-36.27-8.64-36.28-30.91
C167.16,176.65,167.15,146.22,167.13,115.78"/>
<path style="fill:#434345;" d="M349.93,109.19c13.31-2.03,20.23-4.31,33.53-6.35c-0.04,49.77-0.06,99.54-0.1,149.3
c-13.3-2.03-20.03-3.05-33.32-5.08C350.05,200.69,349.89,155.56,349.93,109.19"/>
<path style="fill:#434345;" d="M304.35,240.05c11.61,1.87,18.69,2.8,30.3,4.67c-0.02-32.14-0.03-64.27-0.04-96.4
c-0.01-23.51-13.94-36.55-38.3-32.63c-23.2,3.73-38.28,18.65-38.28,37.88v78.83c12.05,1.93,16.87,2.91,28.91,4.85
c-0.01-21.31-0.01-36.04-0.02-52.37l17.41-1.12C304.34,202.51,304.34,221.28,304.35,240.05 M286.92,163.9c0-3.9,0-8.01,0-12.4
c0-6.28-0.01-12.56,8.12-13.38c9.28-0.95,9.29,5.62,9.29,12.18c0,3.96,0,7.92,0,11.88L286.92,163.9z"/>
<path style="fill:#434345;" d="M64.87,99.33C38.89,95.15,25.97,93.07,0,88.9c0.01,13.83,0.02,20.95,0.03,34.77
c7.42,0.74,11.27,1.13,18.69,1.86c0.02,46.36,0.07,92.73,0.12,139.09c10.98-1.71,16.48-2.55,27.47-4.26
c-0.05-44.04-0.07-88.07-0.11-132.11c7.42,0.74,11.27,1.12,18.68,1.85C64.89,117.87,64.87,111.58,64.87,99.33"/>
<path style="fill:#434345;" d="M149.58,196.66c0.22-17.29-16.48-20.63-16.5-21.09c0,0,15.87-2.42,16.48-30.07
c0.54-24.41-9.92-34.14-35.21-38.21c-14.98-2.41-22.41-3.6-37.39-6.01c0.04,51.44,0.08,102.87,0.12,154.31
c10.99-1.7,16.48-2.56,27.47-4.26c-0.02-25.29-0.03-37.86-0.05-63.15c2.2-0.04,3.29-0.06,5.49-0.1c7.69-0.14,11,3.52,11.01,12.22
c0.01,9.01,0.01,13.36,0.01,22.37c0,8.7,0.01,21.17,3.31,25.6c11.4-1.77,17.17-2.67,28.57-4.44c-3.3-4.1-3.3-15.77-3.31-23.91
C149.59,210.61,149.46,205.96,149.58,196.66 M112.19,163.96c-3.02-0.11-4.67-0.16-7.69-0.27c-0.01-12.89-0.02-19.33-0.03-32.21
c2.2,0.23,3.3,0.35,5.5,0.58c12.1,1.27,12.1,6.22,12.11,16.14C122.08,156.87,122.08,164.31,112.19,163.96"/>
<path style="fill:#00B2E3;" d="M155.47,92.61c10.67,2.75,20.05,5.16,26.7,6.86c19.65-9.74,43.33-15.13,68.05-15.13
c24.13,0,47.39,5.18,66.83,14.54l26.68-7.06c-25.44-15.97-58.84-25.17-93.51-25.17C214.89,66.63,181.04,76.12,155.47,92.61"/>
<path style="fill:#00B2E3;" d="M247.68,54.55c43.99,0,83.73,12.69,112.01,33.03l27.82-7.37c-33.63-27.42-83.81-44.87-139.84-44.87
c-56.07,0-106.29,17.48-139.92,44.93c9.13,2.37,18.71,4.85,28.01,7.25C164.04,67.22,203.73,54.55,247.68,54.55"/>
<path style="fill:#00B2E3;" d="M247.68,0C169.5,0,100.16,26.54,57.53,67.23c0,0,14.02,3.64,33.02,8.57
c36.79-31.94,93.53-52.47,157.13-52.47c63.51,0,120.2,20.47,156.99,52.33l1.7-0.45l30.19-9.17C393.84,26.03,325.1,0,247.68,0"/>
<path style="fill:#434345;" d="M485.93,234.22c0.02-12.79,0.43-19.19,0.03-31.97c-0.77-24.95-19.16-28.01-19.21-28.63
c0,0,20.35-3.5,20.57-41.78c0.19-33.63-12.78-42.89-42.29-38.39c-17.47,2.67-26.13,3.98-43.61,6.65
c-0.04,51.6-0.08,103.19-0.12,154.79c13.3,2.03,20.03,3.05,33.32,5.07c0.02-28.64,0.04-42.88,0.07-71.52
c2.56,0.05,3.85,0.07,6.42,0.12c8.97,0.18,12.81,4.8,12.8,15.38c-0.01,10.96-0.02,16.24-0.03,27.2
c-0.01,10.58-0.02,25.67,2.53,32.14c13.3,2.03,20.02,3.06,33.32,5.09C485.9,261.35,485.91,245.41,485.93,234.22 M453.97,140.46
c-0.01,10.58-0.02,19.65-11.56,20c-3.04,0.09-4.64,0.14-7.69,0.24c0.02-14.6,0.02-21.9,0.03-36.49c2.57-0.26,3.85-0.38,6.42-0.63
C453.98,122.31,453.98,128.36,453.97,140.46"/>
<path style="fill:#00B2E3;" d="M248.73,270.95c-24.72,0-48.4-5.4-68.05-15.13c-6.66,1.69-16.03,4.1-26.71,6.86
c25.57,16.48,59.42,25.97,94.76,25.97c33,0,64.82-8.37,89.75-22.95l-27.93-7.02C292.18,266.59,270.82,270.95,248.73,270.95"/>
<path style="fill:#00B2E3;" d="M246.17,300.73c-43.94,0-83.63-12.67-111.91-32.97c-9.3,2.41-18.88,4.89-28.01,7.25
c33.63,27.45,83.86,44.93,139.92,44.93c54.68,0,103.77-16.64,137.36-42.92l-28.52-7.17C326.96,288.91,288.57,300.73,246.17,300.73"
/>
<path style="fill:#00B2E3;" d="M246.18,331.95c-63.6,0-120.34-20.54-157.13-52.47c-19,4.93-33.02,8.57-33.02,8.57
c42.62,40.69,111.96,67.23,190.15,67.23c77.04,0,145.49-25.78,188.25-65.47l-33.41-8.4C364.19,312.24,308.48,331.95,246.18,331.95"
/>
</g>
<rect x="-28.7" y="-19.26" style="fill:none;" width="919.99" height="673.29"/>
`]
